<template>
  <v-switch
    class="ma-0 pa-0 align"
    hide-details
    v-model="bindedColumn"
    @change="handleChanged"
  />
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  computed: {
    bindedColumn: {
      get () {
        switch (typeof this.value) {
          case 'object':
            return !!this.value.value
          case 'boolean':
          default:
            return this.value
        }
      },
      set () {
      }
    }
  },
  methods: {
    handleChanged () {
      let newValue

      switch (typeof this.value) {
        case 'object':
          newValue = { value: !this.bindedColumn }
          break
        case 'boolean':
        default:
          newValue = !this.bindedColumn
          break
      }

      this.column.options.fn(this.itemId, newValue)
    }
  },
  props: {
    itemId: [String, Number],
    value: {
      type: [Boolean, Object],
      default: false
    },
    column: Column
  }
}
</script>

<style lang="stylus" scoped>
.align
  display inline-block
</style>
